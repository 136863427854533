import React, { useCallback, useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import CustomLegend from "./CustomLegend";
// eslint-disable-next-line import/no-internal-modules
import Chart from "chart.js/auto";

import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
    },
  }),
);

DoughnutChart.propTypes = {
  data: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  width: PropTypes.number,
  position: PropTypes.string,
  cutout: PropTypes.string,
  height: PropTypes.number,
  customLegendIcon: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  legendLabelLocalisation: PropTypes.func,
  activeIndex: PropTypes.array,
  setActiveIndex: PropTypes.func,
};

DoughnutChart.defaultProps = {
  width: 570,
  height: 570,
  position: "top",
  cutout: "70%",
};

function DoughnutChart(props) {
  const [ref, setRef] = useState({});

  const onRefChange = useCallback(node => {
    setRef(node);
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: props.cutout,
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15,
      },
    },
    elements: {
      arc: {
        borderWidth: 5,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: props.position,
        labels: {
          boxWidth: 19,
          fontSize: 18,
        },
      },
      tooltip: {
        mode: "nearest",
        callbacks: {
          title() {
            return null;
          },
          label(tooltipItem) {
            return `${props.legendLabelLocalisation(
              tooltipItem.label,
              props.getLocalisationMessage,
            )}: ${new Intl.NumberFormat("ru-RU").format(tooltipItem.raw)}`;
          },
          afterLabel(tooltipItem) {
            const total = tooltipItem.dataset.data.reduce(
              (acc, x) => acc + x,
              0,
            );
            const percent = Math.round((tooltipItem.raw / total) * 100);
            return `(${percent}%)`;
          },
        },

        titleFontSize: 16,
        bodyFontSize: 14,
        displayColors: false,
      },
    },
  };

  return (
    <div
      style={{
        maxHeight: "630px",
        margin: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1 }}>
        <Doughnut
          ref={onRefChange}
          data={props.data}
          options={options}
          width={props.width}
          height={props.height}
        />
      </div>

      <div style={{ flex: 1, flexDirection: "row" }}>
        <CustomLegend
          activeIndex={props.activeIndex}
          setActiveIndex={props.setActiveIndex}
          legendLabelLocalisation={props.legendLabelLocalisation}
          getLocalisationMessage={props.getLocalisationMessage}
          customLegendIcon={props.customLegendIcon}
          chart={ref}
        />
      </div>
    </div>
  );
}

export default enhancer(DoughnutChart);
